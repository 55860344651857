import React from "react";
import { Button, Navbar, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaHome, FaCog, FaGithub,  } from "react-icons/fa"; 
import { FiExternalLink } from "react-icons/fi";
import Container from 'react-bootstrap/Container';
import '../App.css';

function NavBar() {
  return (
    <Navbar expand="lg" bg="dark" data-bs-theme="dark">
      <Container>
        <Navbar.Brand href="#" style={{ fontSize: '25px' }}>
          <img
            alt=""
            src="./logo.png"
            width="32"
            height="32"
            className="d-inline-block align-top"
          />{' '}
          AhmedSecen.dev
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
          <Nav className="flex-grow-1 justify-content-left flex-wrap">
            <Nav.Item className="mx-1 my-1">
              <Button href="https://github.com/ArtemisE1Tara" target='_blank' variant="outline-primary" className="nav-button d-flex align-items-center">
                GitHub
                <FiExternalLink className="mr-2 mx-1" /> 
              </Button>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
