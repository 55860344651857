import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import '../App.css'
import ListGroup from 'react-bootstrap/ListGroup';


function Models() {
    interface Model {
        id: number;
        name: string;
        description: string;
        format: string;
        downloadLink: string;
        backgroundImage: string; 
        patreonLink?: string;
      }
      
      const hardcodedModels: Model[] = [
        {
          id: 1,
          name: "Sci-Fi Grid",
          description: "Interconnected organic grid with customizable shaders.",
          format: ".fbx, .blend",
          downloadLink: "./grid.zip",
          backgroundImage: "./grid.png", 
        },
      ];

    return(
        <>
        <Row xs={1} md={2} className="g-4">
        {hardcodedModels.map((model) => (
          <Col key={model.id} xs={12} md={6} lg={4}>
            <Card className="mb-4 mod-card">
              <Card.Img
                variant="top"
                src={model.backgroundImage}
                style={{ width: '100%', height: '200px', objectFit: 'cover' }}
              />
              <Card.Body>
                <Card.Title style={{fontSize: '25px', fontWeight: '500'}}>{model.name}</Card.Title>
                <Card.Text style={{fontSize: '20px', fontWeight: '500'}}>{model.description}</Card.Text>
                <Button href={model.downloadLink} target="_blank">
                  Download
                </Button>
              </Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item style={{fontSize: '15px', fontWeight: '400', backgroundColor: '#1a1d20', color: 'white'}} className="outline-primary">Formats - {model.format}</ListGroup.Item>
            </ListGroup>
            </Card>
          </Col>
        ))}
      </Row>
    </>
    );
}

export default Models;
