import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import '../App.css'

function Mods() {
    interface GameMod {
        id: number;
        name: string;
        description: string;
        downloadLink: string;
        backgroundImage: string; 
        patreonLink?: string;
      }
      
      const hardcodedGameMods: GameMod[] = [
        {
          id: 1,
          name: "Quest Supporters Leaderboard",
          description: "A dedicated leaderboard for Quest Supporters.",
          downloadLink: "./QSLeaderboard.zip",
          backgroundImage: "./QSL2.png", 
        },
        {
          id: 2,
          name: "ReeSabers",
          description: "Adds an insane custom saber with tons of customizable options and presets. (Requires a linked patreon subscription to download.)",
          downloadLink: "https://www.beatleader.xyz/supporting-project",
          patreonLink: "https://www.patreon.com/BeatLeader",
          backgroundImage: "./reesabersjuly2.png", 
        },
      ];

    return(
        <>
        <Row xs={1} md={2} className="g-4">
        {hardcodedGameMods.map((mod) => (
          <Col key={mod.id} xs={12} md={6} lg={4}>
            <Card className="mb-4 mod-card">
              <Card.Img
                variant="top"
                src={mod.backgroundImage}
                style={{ width: '100%', height: '200px', objectFit: 'cover' }}
              />
              <Card.Body>
                <Card.Title style={{fontSize: '25px', fontWeight: '500'}}>{mod.name}</Card.Title>
                <Card.Text style={{fontSize: '20px', fontWeight: '500'}}>{mod.description}</Card.Text>
                <Button href={mod.downloadLink} target="_blank" variant="outline-primary">
                  Download
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </>
    );
}

export default Mods;
