import React from "react";
import { Container, Row, Col, Card, Button, Nav } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import NavBar from "./components/NavBar";
import Mods from "./components/Mods";
import { FiLink } from "react-icons/fi";
import { Link } from "react-router-dom";
import SecureComponent from "./components/SecureComponent";
import cardNav from "./components/cardNav";
import SlopeCalculator from "./components/SlopeCalculator";
import Models from "./components/Models";

const Main: React.FC = () => {
  return (
    <>
      <NavBar />

      <Container className="mt-5">
        <Row>
          <Col>
            <h2 style={{ color: "white" }}>About</h2>
            <p style={{ color: "white" }}>
              Welcome to our game mod downloads website! Here you can find and download various game mods to enhance
              your gaming experience. Browse through the available mods below and click on the "Download" button to get
              the ones you like.
            </p>
            <hr />
          </Col>
        </Row>
        <Mods />
        <h2 style={{ color: "white" }}>Quest Supporters Discord</h2>
        <p style={{ color: "white" }}>
          A server for Quest users to talk about VR and Quest, get help, and find people to play with! Mostly focused on
          Quest 1 and 2, but all VR users are welcome!
        </p>
        <Nav className="flex-grow-1 justify-content-left flex-wrap">
          <Button href="https://discord.gg/fdjcvNszxe" target="_blank" variant="outline-primary" className="d-flex align-items-center">
            Discord
            <FiLink className="mr-2 mx-1" />
          </Button>
        </Nav>
        <br />
        <Card className="feature-card">
          <Card.Header as="h4">Admin</Card.Header>
          <Card.Body>
            <Card.Title>Authorized users only</Card.Title>
            <SecureComponent />
          </Card.Body>
        </Card>
        <br />
        <h2 style={{ color: "white" }}>Models</h2>
        <Models />
      </Container>
      <br />
      <br />
    </>
  );
};

export default Main;
