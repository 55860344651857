import React, { useState } from 'react';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useEffect } from 'react';
import cardNav from "./cardNav";

const SecureComponent: React.FC = () => {
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
  
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(event.target.value);
    };
  
    const handleAuthenticate = () => {
      if (password === '1234') {
        setIsAuthenticated(true);
      } else {
        alert('Incorrect password!');
      }
    };
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleEnterKey = (event: Event) => {
        const keyboardEvent = event as KeyboardEvent;
        if (keyboardEvent.key === 'Enter') {
          keyboardEvent.preventDefault();
          handleAuthenticate();
        }
      };
      
  
    useEffect(() => {
      document.addEventListener('keydown', handleEnterKey);
      return () => {
        document.removeEventListener('keydown', handleEnterKey);
      };
    }, [handleEnterKey]);

  const renderContent = () => {
    return (
      <div>
        {/* Content to render after successful authentication */}
        <h2 style={{color: 'red'}}>Protected Content</h2>
        <p>This content is only visible after entering the correct password.</p>
        <Button href={"./ReeSabers_1.27_1.29.zip"} target="_blank" variant="outline-primary" className='mx-2'>
                  ReeSabers 1.27-1.29
        </Button>
        <Button href={"./ReeSabers_1.31.0.zip"} target="_blank" variant="outline-primary">
                  ReeSabers 1.31.0
        </Button>
      </div>
    );
  };

  return (
    <div>
      {!isAuthenticated ? (
        <Form>
          <Row>
            <Col md={2}>
              <Form.Group controlId="password">
                <Form.Control
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Password"
                />
              </Form.Group>
            </Col>
            <Col>
              <Button variant="primary" onClick={handleAuthenticate} >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        renderContent()
      )}
    </div>
  );
};

export default SecureComponent;
